* {
  font-family: 'Rubik';
}

html {
  min-height: 100vh;
  height: 100%;
}

body {
  min-height: 100vh;
  height: 100%;
}

#root{
  min-height: 100vh;
  height: 100%
}

.App{
  min-height: 100vh;
  height: 100%
}

.adminSideBar{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.navPanel{

}

